body {
  margin: 0;
  font-family: "UTM-Avo", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-width: calc(1110px + 70px);
  height: 100vh;
  min-height: 640px;
}

#root{
  width: 100%;
  height: 100%;
}

p{ 
  margin: 0;
  padding: 0;
}

 /* panel */
.ant-picker-cell{
    cursor: default;
}

.ant-calendar-selected-day .ant-calendar-date {
  color: #fff;
  background: #1890ff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "UTM-Avo";
  font-weight: bold;
  font-style: normal;
  src: url("./fonts/utm-avobold.ttf") format("truetype");
}

@font-face {
  font-family: "UTM-Avo";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/utm-avo.ttf") format("truetype");
}
@font-face {
  font-family: "SVN-Gilroy";
  font-weight: bold;
  font-style: normal;
  src: url("./fonts/SVN-Gilroy-Bold.otf") format("truetype");
}

@font-face {
  font-family: "SVN-Gilroy";
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/SVN-Gilroy-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Roboto-medium";
  font-weight: 500;
  font-style: normal;
  src: url("./fonts/Roboto-Medium.ttf") format("truetype");
}

